<template>
    <v-app>
        <v-row justify="center">
            <v-dialog @keydown="handleKeyDown" v-model="addHeroBannerDialog" persistent width="1467px">
                <v-card>
                    <v-card-title style="word-break:normal; padding-left: 16px;">
                        Add Hero Banner
                    </v-card-title>
                    <v-card-actions v-for="(banner, index) of bannerList" :key="index">
                        <div class="showCollasibleBanner d-flex flex-row" v-if="collapsibleBannerFlag">
                            <div class="collapsibleBannerNameClass">
                                Banner {{ String(index + 1) }}
                            </div>
                            <div class="">
                                <span class="collapsibleBannerKeyClass">
                                    Banner Title
                                </span>
                                <span class="collapsibleBannerDetailsClass">
                                    {{ banner.heroBannerName }}
                                </span>
                            </div>
                            <div class="">
                                <span class="collapsibleBannerKeyClass">
                                    Start Date
                                </span>
                                <span class="collapsibleBannerDetailsClass">
                                    {{ banner.heroBannerStartDate }}
                                </span>
                            </div>
                            <div class="">
                                <span class="collapsibleBannerKeyClass">
                                    End Date
                                </span>
                                <span class="collapsibleBannerDetailsClass">
                                    {{ banner.heroBannerExpireDate }}
                                </span>
                            </div>
                            <div class="d-flex ml-2">
                                <img class="mr-2" :src="editIcon" @click="openEditHeroBanner(banner.id)"/>
                                <img class="mr-2" :src="deleteIcon" @click="deleteHeroBanner(banner.id)"/>
                            </div>
                        </div>
                    </v-card-actions>
                    
                    <v-card-actions v-if="collapseAddHeroBannerDiv" 
                        style="border: 1px solid #4F4F4F; border-radius: 8px;box-sizing: border-box;"
                        class="mx-3 mb-6 justify-space-between" width="1403px" height="654px">
                        <div style="width:100%; min-height: 554px;"
                        >
                            <div class="mtba">
                                <v-text-field outlined background-color="#F4F6FA" v-model="heroBannerName" label="Hero Banner Name" 
                                    required :rules="!editFlag ? [noSameName(heroBannerName)] : []"
                                />
                            </div>
                            <div class="d-flex flex-row my-2" style="width:100%; justify-content: space-between;">
                                <div style="width:49%; justify-content: space-evenly;" :class="previewDesktopHBURL ? 'height200' : 'height80'">
                                    <div class="d-flex" style="align-items:center;"> 
                                        <div class="mtba" style="width:10%;">
                                            <img :src="desktopImage" />
                                        </div>
                                        <div class="mtba" style="width:65%;text-align:left">
                                            <div>
                                                Web Banner
                                            </div>
                                            <div>
                                                {{ desktopBannerResolution }}
                                            </div>
                                        </div>
                                        <div v-if="showDesktopUploadButton" class="uploadBannerButtonStyle mtba" style="width:20%; height: fit-content;" @click="onDesktopButtonClick">
                                            <img class="mtba" :src="uploadBannerIcon" />
                                            <p class="mtba">Upload</p> 
                                            <input
                                                ref="uploader"
                                                class="d-none"
                                                type="file"
                                                accept="image/*"
                                                @change="onFileChanged"
                                            />
                                        </div>
                                        <div v-else class="removeButtonStyle mtba" style="width:20%; height: fit-content;" @click="removeDesktopBanner">
                                            <p class="mtba">Remove</p> 
                                            <input
                                                ref="uploader"
                                                class="d-none"
                                                type="file"
                                                accept="image/*"
                                                @change="onFileChanged"
                                            />
                                        </div>
                                    </div>
                                    <div v-if="previewDesktopHBURL" style="width: 100%; height: 124.37px;">
                                        <div style="width: 100%; text-align:center;background: #F4F6FA;">
                                            <img class="mtb20" style="width: 150px; height: 84.37px;" :src="previewDesktopHBURL" alt="Preview Image" />
                                        </div>
                                    </div>
                                </div>
                                <div style="width:49%; justify-content: space-evenly;" :class="previewMobileHBURL ? 'height200' : 'height80'">
                                    <div class="d-flex"> 
                                        <div class="mtba" style="width:10%;">
                                            <img :src="mobileImage" />
                                        </div>
                                        <div class="mtba" style="width:65%;text-align:left">
                                            <div>
                                                Mobile Banner
                                            </div>
                                            <div>
                                                {{ mobileBannerResolution }}
                                            </div>
                                        </div>
                                        <div v-if="showMobileUploadButton" class="uploadBannerButtonStyle mtba" style="width:20%;height: fit-content;" @click="onMobileBannerClick">
                                            <img class="mtba" :src="uploadBannerIcon" />
                                            <p class="mtba">Upload</p>
                                            <input
                                                ref="mobileBannerUploader"
                                                class="d-none"
                                                type="file"
                                                accept="image/*"
                                                @change="onMobileBannerChanged"
                                            />
                                        </div>
                                        <div v-else class="removeButtonStyle mtba" style="width:20%; height: fit-content;" @click="removeMobileBanner">
                                            <p class="mtba">Remove</p> 
                                            <input
                                                ref="mobileBannerUploader"
                                                class="d-none"
                                                type="file"
                                                accept="image/*"
                                                @change="onMobileBannerChanged"
                                            />
                                        </div>
                                    </div>
                                    <div v-if="previewMobileHBURL" style="width: 100%; height: 124.37px;">
                                        <div style="width: 100%; text-align:center;background: #F4F6FA;">
                                            <img class="mtb20" style="width: 150px; height: 84.37px; " :src="previewMobileHBURL" alt="Preview Image" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex flex-row my-2" style="width:100%; justify-content: space-between;">
                                <div class="d-flex flex-column  mtba" style="width:49%; justify-content: space-evenly;">
                                    <div class="" style="text-align: left;">
                                        <span> 
                                            Start Date 
                                        </span>
                                    </div>
                                    <div class=""> 
                                        <v-menu min-width="auto" v-model="heroBannerStartDateMenu" ref="heroBannerStartDateMenu" offset-y transition="scale-transition" :close-on-content-click="false">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field v-model="heroBannerStartDate" class="rounded-lg" style="border-color: #E0E0E0;" outlined
                                                    placeholder="Start Date"
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    required
                                                    background-color="#F4F6FA"
                                                />
                                            </template>
                                            <v-date-picker v-model="heroBannerStartDate" required @change="heroBannerStartDateMenu = false"
                                            :min="minDate" :rules="[startDateRules]"
                                            />
                                        </v-menu>
                                    </div>
                                </div>
                                <div class="d-flex flex-column  mtba" style="width:49%; justify-content: space-evenly;">
                                    <div class="" style="text-align: left;">
                                        <span> 
                                            Expire Date 
                                        </span>
                                    </div>
                                    <div class=""> 
                                        <v-menu min-width="auto" v-model="heroBannerExpireDateMenu" ref="heroBannerExpireDateMenu" offset-y transition="scale-transition" :close-on-content-click="false">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field v-model="heroBannerExpireDate" required class="rounded-lg" style="border-color: #E0E0E0;"
                                                    outlined
                                                    placeholder="Expire Date"
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    background-color="#F4F6FA"
                                                />
                                            </template>
                                            <v-date-picker :min="expiryMinDate" required v-model="heroBannerExpireDate" @change="heroBannerExpireDateMenu = false" />
                                        </v-menu>
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex flex-row my-2" style="width:100%; justify-content: space-between;">
                                <div class="d-flex flex-column " style="width:49%; justify-content: space-evenly;">
                                    <div class="" style="text-align: left;">
                                        <span>Jump Link</span>
                                    </div>
                                    <div class=""> 
                                        <v-autocomplete 
                                        outlined background-color="#F4F6FA"
                                        v-model="jumpLink"
                                        :items="routeNames"
                                        required
                                        />
                                    </div>
                                </div>
                                <div class="d-flex flex-column " style="width:49%; justify-content: space-evenly;" v-if="false">
                                    <div class="mtba" style="text-align: left;">
                                        <span>Custom URL</span>
                                    </div>
                                    <div class="mtba"> 
                                        <v-autocomplete outlined background-color="#F4F6FA" />
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex flex-column  my-2" style="width:100%; justify-content: space-between;">
                                <div class="mtba" style="text-align: left;"> 
                                    <span>Banner Priority</span>
                                </div>
                                <div class="mtba"> 
                                    <v-autocomplete 
                                    outlined background-color="#F4F6FA"
                                    v-model="bannerPriority" 
                                    :items="bannerPriorityItems"
                                    required
                                    />
                                </div>
                            </div>
                            <div class="d-flex flex-row my-2" style="width:100%; justify-content: space-between;"
                            :class="activeStatus ? 'borderBlue': 'discardBorder'">
                                <div 
                                class="mtba mx-3"
                                :class="activeStatus ? 'colorBlue' : 'discardButtonFont'" 
                                style="font-family: 'Inter';
                                        font-style: normal;
                                        font-weight: 600;
                                        font-size: 16px;
                                        line-height: 19px;
                                        height: 19px;
                                        flex: none;
                                        order: 0;
                                        flex-grow: 0;"> 
                                    <span>Active Status: </span>
                                    <span>{{ activeStatus ? 'Active' : 'InActive' }}</span>
                                </div>
                                <div class="mtba"> 
                                    <v-switch v-model="activeStatus"  required />
                                </div>
                            </div>
                        </div>
                    </v-card-actions>
                    <v-card-actions v-if="collapseAddHeroBannerDiv"> 
                        <div 
                        v-if="allFieldsFilled"
                        style="
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;
                        padding: 10px 16px;
                        gap: 10px;
                        width: 152px;
                        height: 36px;
                        background: #48ACEF;
                        cursor: pointer;
                        border-radius: 4px;
                        flex: none;
                        order: 1;
                        flex-grow: 0;"
                        :class="{'enabledDiv': allFieldsFilled}"
                        @click="addMultipleHeroBanner()"
                        >
                            <img :src="multipleAdds"/>
                            <span style="color: #FFFFFF"> 
                            Add Banner
                            </span>
                        </div>
                    </v-card-actions>
                    <v-card-actions> 
                        <div class="d-flex flex-row mtba" style="width:100%; height: 48px; justify-content: space-between;">
                        </div>
                        <div class="d-flex flex-row mtba" style="width:100%; height: 48px; justify-content: end;">
                            <v-btn class="mx-3 saveDiscardButton discardButtonFont" color="#FFFFFF" @click="discardHeroBannerDialog()">Discard</v-btn>
                            <v-btn :disabled="isDisabled"  class="mx-3 saveDiscardButton saveButtonFont" color="#219653" @click="saveHeroBannerDetails()">Save</v-btn>
                        </div>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <div v-if="showMessageDialog">
                <SuccessDialog :successObject="successObject"/>
            </div>
            <div v-if="displayDiscardDialog">
                <DiscardDialog
                    :displayDiscardDialogProps="displayDiscardDialog"
                    @closeDiscardDialog="closeDiscardDialog"
                ></DiscardDialog>
            </div>
        </v-row>
    </v-app>
</template>
<script>
import { axios_auth_instance_epharmacy_admin } from '../utils/axios_utils';
import router from '@/router';
import SuccessDialog from "./SuccessDialog.vue";
import DiscardDialog from '../components/DiscardConfirmationDialog.vue';
export default {
    props:['addHeroBannerDialogProps', 'finalEditValue', 'editHeroBannerDialogProps', 'pageType'],
    components: {
        'SuccessDialog': SuccessDialog,
        'DiscardDialog': DiscardDialog,
    },
    watch: {
        previewDesktopHBURL: function(e) {
            if(this.previewDesktopHBURL) {
                this.uploadIntoS3(this.previewDesktopHBURL, this.heroBannerWebImageName, 'DESKTOP');
            } else {
                this.previewDesktopHBURL = null;
            }
        },
        previewMobileHBURL: function(e) {
            if(this.previewMobileHBURL) {
                this.uploadIntoS3(this.previewMobileHBURL, this.heroBannerMobileImageName, 'MOBILE');
            } else {
                this.previewMobileHBURL = null;
            }
        },
        heroBannerStartDate: function(value) {
            this.heroBannerExpireDate = null;
			this.expiryMinDate = value;
		},
    },
    computed: {
        allFieldsFilled() {
            return this.heroBannerMobileImageURL && this.heroBannerWebImageURL 
            && this.heroBannerStartDate && this.heroBannerName && this.bannerPriority && this.jumpLink;
        },
        startDateRules() {
            return [ 
                (value) => {
                    if(!value) {
                        return 'Date is required' // Basic validation to check if the date is not empty
                    }
                },
                (value) => {
                // Custom validation to check if the selected date is not in the past
                const currentDate = new Date();
                const selectedDate = new Date(value);
                if (selectedDate < currentDate) {
                    return 'Selected date must be in the future';
                }
                return true;
                },
            ];
        },
        isDisabled() {
            return !(this.heroBannerMobileImageURL && this.heroBannerWebImageURL && this.heroBannerExpireDate && this.heroBannerStartDate
            && this.heroBannerName && this.bannerPriority && this.jumpLink);
        },
		noSameName(value) {
			return (value) => {
                let returnFlag =  false;
                if(this.heroBannerNameList && this.heroBannerNameList.includes(value)) {
                    returnFlag = true;
                } else {
                    for (let banner of this.bannerList) {
                        if(banner.heroBannerName == value) {
                            returnFlag = true;
                            break;
                        }
                    }
                }
                if(returnFlag) return "Hero Banner Name Already Exists";
                if (!value.trim()) return "Give a valid hero banner name";
                else return true;
            }
		},
	},
    data() {
        return {
            minDate: new Date().toISOString().substr(0, 10), // Set the minimum date to today's date
            expiryMinDate: new Date().toISOString().substr(0, 10),
            displayDiscardDialog: false,
            successObject: {},
            showMessageDialog: false,
            collapseAddHeroBannerDiv: true,
            collapsibleBannerFlag: false,
            bannerList: [],
            addHeroBannerDialog: true,
            heroBannerName: '',
            editFlag: false,
            selectedFile: null,
            selectedMobileFile: null,
            showMobileUploadButton: true,
            showDesktopUploadButton: true,
            previewDesktopHBURL: null,
            previewMobileHBURL: null,
            desktopImage: 'desktopImage.svg',
            mobileImage: 'mobileImage.svg',
            uploadBannerIcon: 'uploadBannerIcon.svg',
            cloudFront: process.env.VUE_APP_CLOUDFRONT_URL_FOR_IMAGES,
            editIcon: 'editIcon.svg',
            deleteIcon: 'deleteIcon.svg',
            mobileBannerResolution: 'Ratio: 16:1 | Resolution: Width - 335px, Height - 188px',
            desktopBannerResolution: 'Ratio: 4:1 | Resolution: Width - 1520px, Height - 380px',
            webImageSizeWidth: 1520,
            webImageSizeHeight: 380,
            mobileImageSizeWidth: 335,
            mobileImageSizeHeight: 188,
            heroBannerStartDateMenu: false,
			heroBannerExpireDateMenu: false,
            heroBannerMobileImageName: null,
            heroBannerWebImageName: null,
            heroBannerMobileImageURL: '',
            heroBannerWebImageURL: '',
			heroBannerStartDate: '',
			heroBannerExpireDate: '',
			jumpLink: '',
            routeNames: [],
            bannerPriority: '',
            bannerPriorityItems: [1,2,3,4,5,6,7,8,9],
            activeStatus: false,
            multipleAdds: 'multipleAdds.svg',
            heroBannerNameList: [],
        };
    },
    methods: {
        closeDiscardDialog(value) {
            if(value.value) {
                this.addHeroBannerDialog = false;
                this.$emit("closeCancelOperation", this.addHeroBannerDialog);
            } else {
                this.displayDiscardDialog = false;
            }
        },
        async uploadIntoS3(url, name, displayDevice) {
            let fileName = `${String(Date.now())}_${displayDevice}_${name}`;
            let s3UploadData = { 
                base64Str: url,
                fileType: displayDevice === 'DESKTOP' ? this.contentDesktopType : this.contentMobileType,
                fileName: fileName,
            }
            if(s3UploadData.fileType) {
                try {
                    let s3UploadResponse = await axios_auth_instance_epharmacy_admin.post("/admin/bannerUploadIntoS3", s3UploadData);
                    if(s3UploadResponse && s3UploadResponse.data && s3UploadResponse.data.data) {
                        if(displayDevice === 'DESKTOP') {
                            this.heroBannerWebImageName = fileName;
                            this.heroBannerWebImageURL = this.cloudFront + fileName;
                            this.showDesktopUploadButton = false;
                        }
                        if(displayDevice === 'MOBILE') {
                            this.heroBannerMobileImageName = fileName;
                            this.heroBannerMobileImageURL = this.cloudFront + fileName;
                            this.showMobileUploadButton = false;
                        }
                    } else {
                        // this.$cookies.remove('token');
                        // this.$router.push({
                        //     name: "AdminLogin",
                        // });
                    }         
                } catch (error) {
                    if(error && error.response && error.response.status == 401) {
                        window.alert("Sorry !! You are unauthorized !!");
                        this.$cookies.remove('token');
                        this.$router.push({
                            name: "AdminLogin",
                        });
                    }
                }
            }
        },
        openEditHeroBanner(bannerId) {
            for(let banner of this.bannerList) {
                if(bannerId === banner.id) {
                    this.heroBannerName = banner.heroBannerName;
                    this.heroBannerStartDate = banner.heroBannerStartDate
                    this.heroBannerExpireDate = banner.heroBannerExpireDate
                    this.jumpLink = banner.jumpLink
                    this.customURL = banner.customURL
                    this.bannerPriority = banner.bannerPriority
                    this.activeStatus = banner.activeStatus
                    this.collapseAddHeroBannerDiv = true;
                }
            }

            let index = this.bannerList.findIndex(obj => obj.id === bannerId);
            if (index !== -1) {
                this.bannerList.splice(index, 1);
            }
        },
        deleteHeroBanner(bannerId) {
            for(let banner of this.bannerList) {
                if(bannerId === banner.id) {
                    // Replace 'objectToRemove' with the actual object you want to remove
                    var index = this.bannerList.indexOf(banner); 
                    if (index > -1) {
                        this.bannerList.splice(index, 1);
                        this.clearHeroBannerDetails();
                        this.collapseAddHeroBannerDiv = true;
                    }
                }
            }
        },
        async saveHeroBannerDetails() {
            if(!this.editFlag) {
                if(this.bannerList.length <= 0) {
                    let id = 1;
                    this.bannerList.push({
                        id: 'Banner '+String(id),
                        heroBannerName: this.heroBannerName,
                        heroBannerStartDate: this.heroBannerStartDate,
                        heroBannerExpireDate: this.heroBannerExpireDate,
                        heroBannerWebImageName: this.heroBannerWebImageName,
                        heroBannerMobileImageName: this.heroBannerMobileImageName,
                        heroBannerWebImageURL: this.heroBannerWebImageURL,
                        heroBannerMobileImageURL: this.heroBannerMobileImageURL,
                        jumpLink: this.jumpLink,
                        customURL: this.customURL,
                        bannerPriority: this.bannerPriority,
                        activeStatus: this.activeStatus,
                    });
                } else {
                    if(!(this.heroBannerName === '' || this.heroBannerName === undefined)) {
                        let id = this.bannerList.length + 1;
                        this.bannerList.push({
                            id: 'Banner '+String(id),
                            heroBannerName: this.heroBannerName,
                            heroBannerStartDate: this.heroBannerStartDate,
                            heroBannerExpireDate: this.heroBannerExpireDate,
                            heroBannerWebImageName: this.heroBannerWebImageName,
                            heroBannerMobileImageName: this.heroBannerMobileImageName,
                            heroBannerWebImageURL: this.heroBannerWebImageURL,
                            heroBannerMobileImageURL: this.heroBannerMobileImageURL,
                            jumpLink: this.jumpLink,
                            customURL: this.customURL,
                            bannerPriority: this.bannerPriority,
                            activeStatus: this.activeStatus                
                        });
                    }
                    this.collapseAddHeroBannerDiv = true;
                }
                let heroBannerData = {
                    bannerList: this.bannerList,
                    pageType: this.$props.pageType,
                }
                let saveHeroBannerDetailsResponse = {};
                try {
                    saveHeroBannerDetailsResponse = await axios_auth_instance_epharmacy_admin.post("/admin/addHeroBanner", heroBannerData);
                } catch (error) {
                    if(error && error.response && error.response.status == 401) {
                        console.error('[ERROR] error', error);
                        window.alert("Sorry !! You are unauthorized !!");
                        this.$cookies.remove('token');
                        this.$router.push({
                            name: "AdminLogin",
                        });
                    }
                }
                if(saveHeroBannerDetailsResponse) {
                    this.heroBannerWebImageName = '';
                    this.heroBannerWebImageURL = '';
                    this.heroBannerMobileImageName = '';
                    this.heroBannerMobileImageURL = ''
                    this.isLoading = false;
                    this.addHeroBannerDialog = false;
                    this.$emit('closeHeroBannerDialog', this.addHeroBannerDialog);
                }
            } else {
                this.updateHeroBanner();
            }
        },
        addMultipleHeroBanner() {
            let id = this.bannerList.length + 1;
            this.bannerList.push({
                id: 'Banner '+String(id),
                heroBannerName: this.heroBannerName,
                heroBannerStartDate: this.heroBannerStartDate,
                heroBannerExpireDate: this.heroBannerExpireDate,
                heroBannerWebImageName: this.heroBannerWebImageName,
                heroBannerMobileImageName: this.heroBannerMobileImageName,
                heroBannerWebImageURL: this.heroBannerWebImageURL,
                heroBannerMobileImageURL: this.heroBannerMobileImageURL,
                jumpLink: this.jumpLink,
                customURL: this.customURL,
                bannerPriority: this.bannerPriority,
                activeStatus: this.activeStatus                
            });
            this.clearHeroBannerDetails();
            this.collapsibleBannerFlag = true;
            this.showDesktopUploadButton = true;
            this.showMobileUploadButton = true;
        },
        clearHeroBannerDetails() {
            this.heroBannerName = '';
            this.heroBannerStartDate = '';
            this.heroBannerExpireDate = '';
            this.heroBannerWebImageURL = '';
            this.heroBannerMobileImageURL = ''
            this.heroBannerWebImageName = '';
            this.heroBannerMobileImageName = ''
            this.previewDesktopHBURL = '';
            this.previewMobileHBURL = '';
            this.jumpLink = '';
            this.customURL = '';
            this.bannerPriority = '';
            this.activeStatus = false;
        },
        handleKeyDown(event) {
            if (event.keyCode === 27) {
                this.clickNo();
            }
        },
        onDesktopButtonClick() {
            this.isSelecting = true
            window.addEventListener('focus', () => {
                this.isSelecting = false
            });

            this.$refs.uploader.click();
        },
        onMobileBannerClick() {
            this.isMobileSelecting = true
            window.addEventListener('focus', () => {
                this.isMobileSelecting = false
            });
            this.$refs.mobileBannerUploader.click();
        },
        async removeDesktopBanner() {
            this.previewDesktopHBURL = null;
            this.showDesktopUploadButton = true;
        },
        async removeMobileBanner() {
            this.previewMobileHBURL = null;
            this.showMobileUploadButton = true;
        },
        async onFileChanged(e) {
            this.selectedFile = e.target.files[0];
            this.heroBannerWebImageName = this.selectedFile.name;
            this.removeDesktopBannerName = this.selectedFile.name;
            this.contentDesktopType = this.selectedFile.type;
            if (this.selectedFile) {
                const reader = new FileReader();
                reader.onload = (event) => {
                    const image = new Image();
                    image.src = event.target.result;

                    image.onload = () => {
                        // Validate the image size
                        // if (image.width < this.webImageSizeWidth) {
                        //     alert('Invalid Image  Size. Please Upload Image of Width: 1520px')
                        //     this.$data.previewDesktopHBURL = null;
                        //     this.showDesktopUploadButton = true;
                        // } else if (image.height < this.webImageSizeHeight) {
                        //     alert('Invalid Image  Size. Please Upload Image of Height: 380px')
                        //     this.$data.previewDesktopHBURL = null;
                        //     this.showDesktopUploadButton = true;
                        // } else if(image.width == this.webImageSizeWidth && image.height == this.webImageSizeHeight) {
                        this.$data.previewDesktopHBURL = event.target.result;
                        // } else {
                        //     alert('Invalid Image  Size. Please Upload Image of Width: 1520px  X  Height: 380px')
                        //     this.$data.previewDesktopHBURL = null;
                        //     this.showDesktopUploadButton = true;
                        // }
                    }
                };
                reader.readAsDataURL(this.selectedFile);
            } else {
                this.previewDesktopHBURL = null;
            }
            this.showDesktopUploadButton = false;
		},
        onMobileBannerChanged(e) {
            this.selectedMobileFile = e.target.files[0];
            this.heroBannerMobileImageName = this.selectedMobileFile.name;
            this.removeMobileBannerName = this.selectedMobileFile.name;
            this.contentMobileType = this.selectedMobileFile.type;
            const reader = new FileReader();
            if (this.selectedMobileFile) {
                reader.onload = (event) => {
                    const image = new Image();
                    image.src = event.target.result;
                    image.onload = () => {
                        // Validate the image size
                        // if (image.width < this.mobileImageSizeWidth) {
                        //     alert('Invalid Image  Size. Please Upload Image of Width: 335px')
                        //     this.previewMobileHBURL = null;
                        //     this.showMobileUploadButton = true;
                        // } else if (image.height < this.mobileImageSizeHeight) {
                        //     alert('Invalid Image  Size. Please Upload Image of Height: 188px')
                        //     this.previewMobileHBURL = null;
                        //     this.showMobileUploadButton = true;
                        // } else if(image.width == this.mobileImageSizeWidth && image.height == this.mobileImageSizeHeight) {
                        this.previewMobileHBURL = event.target.result;
                        // } else {
                        //     alert('Invalid Image  Size. Please Upload Image of Width: 335px  X  Height: 188px')
                        //     this.previewMobileHBURL = null;
                        //     this.showMobileUploadButton = true;
                        // }
                    }
                };
                reader.readAsDataURL(this.selectedMobileFile);
            } else {
                this.previewMobileHBURL = null;
            }
            this.showMobileUploadButton = false;
        },
        getRouteNames() {
            // return router.options.routes.map(route => route.name)
            return router.options.routes
            .filter(route => route.visibility === true)
            .map(route => route.name);
        },
        discardHeroBannerDialog() {
            this.displayDiscardDialog = true;
        },
        clickNo() {
            this.addHeroBannerDialog = false;
            this.clearHeroBannerDetails();
            this.$emit("closeCancelOperation", false);
        },
        async updateHeroBanner() {
			if(this.editFlag) {
				let updateHeroBannerData = {
                    pageType: this.$props.pageType,
                    shortID: this.shortID,
					heroBannerName: this.heroBannerName,
					heroBannerWebImageName: this.heroBannerWebImageName,
					heroBannerMobileImageName: this.heroBannerMobileImageName,
					heroBannerWebImageURL: this.heroBannerWebImageURL,
                    heroBannerMobileImageURL: this.heroBannerMobileImageURL,
					activeStatus: this.activeStatus,
					bannerPriority: this.bannerPriority,
					jumpLink: this.jumpLink,
					heroBannerStartDate: this.heroBannerStartDate,
					heroBannerExpireDate: this.heroBannerExpireDate,
					createdBy: 'User',
					updatedBy: 'User'
				}
                try {
                    let updateHeroBannerResponse = await axios_auth_instance_epharmacy_admin.post("/admin/updateHeroBanner", updateHeroBannerData);
                    if(updateHeroBannerResponse && updateHeroBannerResponse.data && updateHeroBannerResponse.data.data && updateHeroBannerResponse.data.data.Attributes) {
                        this.addNewBannerDialogFlag = false;
                        this.editFlag = false;
                        this.$emit('updateHeroBannerRecord', true);
                    } else {

                    }
                } catch (error) {
                    if(error && error.response && error.response.status == 401) {
                        window.alert("Sorry !! You are unauthorized !!");
                        this.$cookies.remove('token');
                        this.$router.push({
                            name: "AdminLogin",
                        });
                    }
                }
			} else {
                console.error('No Edit Flag');
            }
		},
    },
    async mounted() {
        this.multipleAdds = this.cloudFront + this.multipleAdds;
        this.desktopImage = this.cloudFront + this.desktopImage;
        this.mobileImage = this.cloudFront + this.mobileImage;
        this.uploadBannerIcon = this.cloudFront + this.uploadBannerIcon;
        this.calendar = this.cloudFront + this.calendar;
        this.editIcon = this.cloudFront + this.editIcon;
        this.deleteIcon = this.cloudFront + this.deleteIcon;
        this.routeNames = this.getRouteNames();
        try {
            let res = await axios_auth_instance_epharmacy_admin.post("/admin/getAllHeroBannerNames", {
                pageType: this.$props.pageType
            });
            if(res && res.data && res.data.data) {
                this.heroBannerNameList = res.data.data 
            } else {
                // this.$cookies.remove('token');
                // this.$router.push({
                //     name: "AdminLogin",
                // });
            }
        } catch (error) {
            if(error && error.response && error.response.status == 401) {
                window.alert("Sorry !! You are unauthorized !!");
                this.$cookies.remove('token');
                this.$router.push({
                    name: "AdminLogin",
                });
            }
        }
        if(this.$props.editHeroBannerDialogProps) {
            this.addHeroBannerDialog = this.$props.editHeroBannerDialogProps;
            this.showDesktopUploadButton = false;
            this.showMobileUploadButton = false;
            this.editFlag = this.$props.editHeroBannerDialogProps;
            this.shortID = this.finalEditValue.short_id;
            this.heroBannerName = this.finalEditValue.display_data.heroBannerName;
            this.activeStatus = this.finalEditValue.display_data.activeStatus;
            this.bannerPriority = this.finalEditValue.display_data.bannerPriority;
            this.heroBannerWebImageName = this.finalEditValue.display_data.heroBannerWebImageName;
            this.heroBannerMobileImageName = this.finalEditValue.display_data.heroBannerMobileImageName;
            this.previewDesktopHBURL = this.finalEditValue.display_data.heroBannerWebImageURL;
            this.previewMobileHBURL = this.finalEditValue.display_data.heroBannerMobileImageURL.trim();
            this.heroBannerWebImageURL = this.finalEditValue.display_data.heroBannerWebImageURL.trim();
            this.heroBannerMobileImageURL = this.finalEditValue.display_data.heroBannerMobileImageURL.trim();
            this.jumpLink = this.finalEditValue.display_data.jumpLink;
            this.heroBannerStartDate = this.finalEditValue.display_data.heroBannerStartDate;
            this.heroBannerExpireDate = this.finalEditValue.display_data.heroBannerExpireDate;
        } else {
            this.addHeroBannerDialog = this.$props.addHeroBannerDialogProps;
        }
    },
  };
</script>

<style scoped>
.custom-append-icon .v-input__icon--append {
  background-image: url("https://d3vsnl8idgwrlw.cloudfront.net/calendar.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.bannerBorder {
    border: 1px solid #E0E0E0;
    border-radius: 8px;
}
.mtba {
    margin-top: auto;
    margin-bottom: auto;
}
.colorBlue {
    color: #48ACEF;
}
.uploadBannerButtonStyle {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 16px;
    gap: 10px;
    width: 117px;
    height: 36px;
    color: #FFFFFF;
    background: #48ACEF;
    border-radius: 4px;
    flex: none;
    order: 1;
    flex-grow: 0;
}
.saveDiscardButton {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 24px;
    gap: 16px;
    width: 176px;
    height: 48px;
    border-radius: 4px;
    flex: none;
    order: 1;
    flex-grow: 0;
}
.discardButtonFont {
    color: #828282;
}
.saveButtonFont {
    color: #FFFFFF;

}
.btnClass {
  background: transparent !important;
  color: rgb(228, 26, 26) !important;
  margin-right: -8%;
  font-size: 12pt !important;
}
.removeButtonStyle {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 16px;
    gap: 10px;
    width: 112px;
    height: 36px;
    background: #EB5757;
    color: #FFFFFF;
    border-radius: 4px;
    flex: none;
    order: 1;
    flex-grow: 0;
}
.mtb20 {
    margin-top: 20px;
    margin-bottom: 20px;
}
.height80 {
    border: 1px solid #E0E0E0;
    border-radius: 8px;
    height: 54px;
}

.height200 {
    border: 1px solid #E0E0E0;
    border-radius: 8px;
    height: 196.38px;
}
.collapsible {
    cursor: pointer;
    padding: 18px;
    background-color: #f1f1f1;
    border: none;
    text-align: left;
    outline: none;
    width: 100%;
}

.content {
    padding: 0 18px;
    display: block;
    overflow: hidden;
    background-color: #ffffff;
    height: fit-content;
}
.showCollasibleBanner {
    height: 64px;
    width: 100%;
    padding: 16px;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #E0E0E0;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.06);
    border-radius: 8px;
    flex: none;
    order: 2;
    align-self: stretch;
    flex-grow: 0;
}
.collapsibleBannerNameClass {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #333333;
}
.collapsibleBannerDetailsClass {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #333333;
}
.collapsibleBannerKeyClass {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #828282;
}

.discardBorder {
    border: 1px solid #828282; border-radius: 6px;
}
.borderBlue {
    border: 1px solid #48ACEF; border-radius: 6px;
}
.enabledDiv {
    padding: 10px 16px;
    gap: 10px;
    width: 200px;
    height: 42px;
    background: #48ACEF;
    border-radius: 4px;
    cursor: pointer;
    flex: none;
    order: 1;
    flex-grow: 0;
}
</style>